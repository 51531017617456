@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply overflow-x-hidden text-[15px];
    @apply text-neutral-900;
    scrollbar-gutter: stable;
  }

  body[style] {
    margin: 0 auto !important;
  }

  body[data-scroll-locked] {
    overflow: hidden;
    position: fixed;
    width: 100%;
  }

  a,
  button,
  input,
  textarea {
    @apply focus-visible:outline-none;
  }

  @screen m {
    html {
      @apply text-[17px];
    }
  }
}

@media print {
  .force-print-background {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  .force-print-hide {
    display: none !important;
  }
  .force-print-show {
    display: flex !important;
  }
}

@layer components {
  .button {
    @apply rounded-small border-2 px-[24px] py-[13px] text-bodyBold3 after:content-[''] disabled:cursor-not-allowed disabled:opacity-40;
  }

  .button.default {
    @apply inline-flex items-center justify-center rounded border border-gray-300 bg-white px-6 py-3 text-base font-medium leading-snug tracking-tight text-neutral-800;
  }

  .button-primary {
    @apply rounded-small border-brandElectric bg-brandElectric text-subtitle14px font-medium text-neutral-900;
  }
  .button-primary [data-loading='true'] {
    @apply border-b-brandMidnight;
  }

  .button-secondary {
    @apply border-brandMidnight bg-brandMidnight text-blue-light focus:border-b-brandElectric focus-visible:border-brandElectric;
  }

  .button-secondary[data-loading='true'] {
    @apply border-b-brandElectric;
  }

  .button-secondary path {
    @apply stroke-blue-light;
  }

  .button-tertiary {
    @apply border-brandElectric text-brandMidnight hover:shadow-[0_0_0_2px_theme(colors.brandElectric.DEFAULT)_inset] focus:bg-green-light focus-visible:bg-green-light;
  }

  .button-tertiary[data-loading='true'] {
    @apply bg-green-light;
  }
}

@layer components {
  .link {
    @apply inline-flex items-center;
  }

  .link-normal {
    @apply space-x-[8px] text-bodyBold4;
  }

  .link-small {
    @apply space-x-[6px] text-micro2;
  }

  .link-normal:not(.link-default) {
    @apply px-[7.5px] py-[4px];
  }

  .link-small:not(.link-default) {
    @apply px-[4px] py-[2px];
  }

  .link-primary {
    @apply text-brandElectric hover:bg-green-light focus-visible:bg-green-light focus-visible:shadow-[0_0_0_2px_theme(colors.brandElectric.DEFAULT)_inset];
  }

  .link-secondary {
    @apply text-neutral-600 hover:bg-blue-light focus-visible:bg-blue-light focus-visible:shadow-[0_0_0_2px_theme(colors.blue.medium)_inset];
  }

  .link-tertiary {
    @apply text-blue-dark hover:bg-blue-light focus-visible:bg-blue-light focus-visible:shadow-[0_0_0_2px_theme(colors.blue.medium)_inset];
  }
}

@layer components {
  .text-button {
    @apply inline-flex items-center disabled:cursor-not-allowed disabled:opacity-40;
  }

  .text-button-normal {
    @apply space-x-[8px] text-bodyBold4;
  }

  .text-button-small {
    @apply space-x-[6px] text-micro2;
  }

  .text-button-normal:not(.text-button-default) {
    @apply px-[7.5px] py-[4px];
  }

  .text-button-small:not(.text-button-default) {
    @apply px-[4px] py-[2px];
  }

  .text-button-primary {
    @apply text-brandElectric hover:bg-green-light focus-visible:bg-green-light focus-visible:shadow-[0_0_0_2px_theme(colors.brandElectric.DEFAULT)_inset];
  }

  .text-button-secondary {
    @apply text-neutral-600 hover:bg-blue-light focus-visible:bg-blue-light focus-visible:shadow-[0_0_0_2px_theme(colors.blue.medium)_inset];
  }

  .text-button-tertiary {
    @apply text-blue-dark hover:bg-blue-light focus-visible:bg-blue-light focus-visible:shadow-[0_0_0_2px_theme(colors.blue.medium)_inset];
  }
}

@layer components {
  /* width */
  .modal-scroll-bar::-webkit-scrollbar {
    width: 6px;
    margin-right: 10px;
  }

  /* Track */
  .modal-scroll-bar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0 5px theme('colors.neutral.200');
    border-radius: 10px;
  }

  /* Handle */
  .modal-scroll-bar::-webkit-scrollbar-thumb {
    background: theme('colors.neutral.600');
    border-radius: 10px;
  }
}

@layer components {
  .no-buttons::-webkit-inner-spin-button,
  .no-buttons::-webkit-outer-spin-button,
  .no-buttons::-webkit-search-cancel-button,
  .no-buttons::-webkit-search-decoration,
  .no-buttons::-webkit-search-results-button,
  .no-buttons::-webkit-search-results-decoration {
    display: none;
  }
}

@layer components {
  .textarea {
    @apply h-[120px] resize-none rounded-[6px] border-[2px] border-blue-light bg-neutral-0 px-[14px] py-[10px] text-body2Light focus:border-blue-light focus-visible:border-blue-light disabled:opacity-50;
  }
}

@layer components {
  .form-input {
    @apply relative block;
  }

  .form-input-search input {
    background-repeat: no-repeat;
    background-position: right 16px center;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 14L11.1 11.1M12.6667 7.33333C12.6667 10.2789 10.2789 12.6667 7.33333 12.6667C4.38781 12.6667 2 10.2789 2 7.33333C2 4.38781 4.38781 2 7.33333 2C10.2789 2 12.6667 4.38781 12.6667 7.33333Z' stroke='%23516670' strokeWidth='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  }
}

@layer components {
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .hide-scrollbar {
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@layer components {
  .error {
    @apply border-red-medium bg-red-light focus:border-red-medium focus-visible:border-red-medium;
  }

  .error-message {
    @apply mt-[4px] hidden items-center text-red-dark;
  }
}

@layer components {
  .fadeIn {
    animation: fadeIn 0.3s ease-out forwards;
  }

  .fadeOut {
    animation: fadeOut 0.3s ease-out forwards;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
